<template>
  <!-- 进件结果页面 -->
  <div class="container">
    <!-- 顶部 -->
    <div class="container-top u-flex">
      <img src="../assets/images/icon_trial_success_1.png" />
      <div class="right u-flex-col">
        <span class="title">贷款预审已通过</span>
        <span class="content">等待合作方电话复审</span>
      </div>
    </div>

    <!-- 产品信息 -->
    <div class="product-info u-flex-col">
      <div class="top u-flex">
        <img
          class="top-image"
          referrerpolicy="no-referrer"
          :src="this.productLogo"
        />
        <div class="top-right u-flex-col">
          <span class="top-right-text"
            >您好!{{ this.productName }}出具的预估额度为</span
          >
          <div class="top-right-bottom">
            <span class="top-right-bottom-text">￥</span>
            <span class="top-right-bottom-amount">{{
              this.form.loanAmount
            }}</span>
          </div>
        </div>
      </div>
      <div class="center u-flex u-row-between">
        <div class="center-top u-flex u-row-center">
          <span class="center-top-text">大额低息</span>
        </div>
        <div class="center-top u-flex u-row-center">
          <span class="center-top-text">灵活分期</span>
        </div>
        <div class="center-top u-flex u-row-center">
          <span class="center-top-text">随借随还</span>
        </div>
      </div>
      <div class="bottom">
        <span
          v-if="isRedirectProductsPage === 1"
          class="bottom-text u-flex u-row-center"
          @click="handleIGotIt()"
          >确认额度({{ this.countDC }})</span
        >
        <span v-else class="bottom-text u-flex u-row-center"
          >同意, 去APP激活额度</span
        >
      </div>
    </div>

    <!-- 客户经理 -->
    <div class="group_2 u-flex-col">
      <span v-if="!callMobile" class="text_1">等待客户经理为您服务</span>
      <span v-else class="text_1">将由该客户经理为您服务</span>
      <div class="section_2 u-flex">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="../assets/images/icon_trial_success_2.png"
        />
        <div class="section_2-right">
          <div v-if="!callMobile" class="text-group_7 u-flex-col u-row-between">
            <span class="text_2"
              >首次借款需要客户经理电话联系您确认相关信息，请注意接听来电</span
            >
          </div>
          <div v-else class="text-group_7 u-flex-col u-row-between">
            <span class="phone">{{ this.callMobile }}</span>
            <span class="text_2">请注意接听该号码来电</span>
          </div>
        </div>
      </div>
      <div class="text-wrapper_1">
        <span class="text_4 u-flex u-row-center"
          >合作方将于30分钟内联系您(9:00-18:00)</span
        >
      </div>
    </div>

    <!-- 贷款流程 -->
    <div class="loan-process u-flex-col">
      <span class="loan-process-title">贷款流程</span>
      <div class="loan-process-content u-flex u-row-between">
        <div class="item u-flex-col u-flex-1 u-col-center">
          <img
            class="label"
            referrerpolicy="no-referrer"
            src="../assets/images/icon_trial_success_3.png"
          />
          <span class="text-title">风控系统审核</span>
          <div class="div-content u-flex u-row-between">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="../assets/images/icon_trial_success_4.png"
            />
            <span class="status">已通过</span>
          </div>
          <div class="div-bottom u-flex u-row-center">
            <span class="text">可放款</span>
          </div>
        </div>
        <div class="item u-flex-col u-flex-1 u-col-center center">
          <img
            class="label"
            referrerpolicy="no-referrer"
            src="../assets/images/icon_trial_success_5.png"
          />
          <span class="text-title">电话复审</span>
          <span class="status">进行中...</span>
          <div class="div-bottom u-flex u-row-center">
            <span class="text">注意接听</span>
          </div>
        </div>
        <div class="item u-flex-col u-flex-1 u-col-center">
          <img
            class="label"
            referrerpolicy="no-referrer"
            src="../assets/images/icon_trial_success_6.png"
          />
          <span class="text-title">APP取款</span>
          <span class="status">等待复审</span>
          <div class="div-bottom u-flex u-row-center">
            <span class="text">注意接听</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部风险提示 -->
    <div class="bottom u-text-center">
      贷款有风险，借款需谨慎<br />
      综合年化6%-24%，最终结果以审批为准<br />
      请根据个人能力合理贷款，理性消费<br />
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>
<script>
import * as API_Common from "@/api/common.js";
export default {
  name: "TrialSuccessTl8",
  data() {
    return {
      form: {
        channelNo: this.$route.query.source, // 渠道标识
        loanAmount: this.$route.query.amount ? this.$route.query.amount : 0, // 借款金额
      }, // 表单信息
      applyId: this.$route.query.applyId, // 申请编号
      brand: this.$route.query.brand, // 设备型号
      downPageUrl: this.$route.query.downPageUrl, // 进件结果下载页地址
      isRedirectProductsPage: parseInt(
        this.$route.query.isRedirectProductsPage
      ), // 标识是否是贷超流程的字段1是 0否
      isRedirectWorkWeixinPage: parseInt(
        this.$route.query.isRedirectWorkWeixinPage
      ), // 标识如果不是贷超流程需要用此字段进行判断是否跳转引导企业微信页面中，引导用户用企业微信扫码联系客服1是 0否
      downAppUrl: "", // app下载链接
      productName: "", // 产品名称
      productLogo: "", // 产品logo
      callMobile: "", // 外呼号码
      countDC: 5, // 贷超倒计时
      timer: null, // 计时器
      showPop: false, // 分享蒙层状态
    };
  },
  mounted() {
    // 如果进件申请id不为0则查询机构信息
    if (this.applyId !== "0") {
      this.getMechanismInfo();
    }
    this.dynamicLoadIco(); // 动态加载ico
    // 获取下载APP链接
    this.getDownloadUrl();

    // 倒计时跳转贷超页面
    if (this.isRedirectProductsPage === 1) {
      this.timer = setInterval(() => {
        this.countDC--;
        if (this.countDC === 0) {
          clearInterval(this.timer);
          this.handleIGotIt();
        }
      }, 1000);
    }
  },
  methods: {
    // 动态加载Ico文件
    dynamicLoadIco() {
      var link = "../img/lxricon.ico";
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) {
        $favicon.href = link;
      } else {
        $favicon = document.createElement("link");
        $favicon.rel = "icon";
        $favicon.href = link;
        document.head.appendChild($favicon);
      }
    },
    // 获取机构信息
    getMechanismInfo() {
      API_Common.getMechanismInfo(this.applyId).then((res) => {
        if (res?.code === 200) {
          // 进件是否推送成功;1:成功(会返回对应的产品信息)、0:失败(不会返回产品信息)
          if (res.data.hasPushSuccess === 1) {
            this.productName = res.data.productName;
            this.productLogo = res.data.productLogo;
            this.callMobile = res.data.callMobile;
          }
        } else {
          this.$toast(res?.msg);
        }
      });
    },

    // 获取下载地址
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.form.channelNo, true).then((res) => {
        if (res?.code === 200) {
          this.downAppUrl = res.data.url;
        } else {
          this.$toast.fail("获取下载地址失败,请重试");
        }
      });
    },

    // 点击确认额度
    handleIGotIt() {
      var timestamp = new Date().getTime();
      window.location.href = `/productList.html?source=${this.form.channelNo}&tsp=${timestamp}`;
    },

    // 下载按钮点击
    handleDownload() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        if (this.brand === "xiaomi") {
          window.location.href = "https://app.xiaomi.com/detail/1500555";
        } else {
          window.location.href = this.downAppUrl;
        }
      }
    },

    // 监听提示浏览器打开弹层
    handlePopChange(show) {
      this.showPop = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  padding-bottom: 0.1rem;
  background-color: #f5f5f5;

  .container-top {
    background: url("../../public/img/register/register_tl4_bg.png");
    width: 100%;
    height: 2.38rem;
    background-size: 100% 2.38rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;

    > img {
      width: 0.88rem;
      height: 0.88rem;
    }

    .right {
      margin-left: 0.39rem;

      .title {
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 0.32rem;
        color: #fb6b62;
        line-height: 0.4rem;
      }

      .content {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 0.28rem;
        color: #202020;
        line-height: 0.4rem;
        margin-top: 0.21rem;
      }
    }
  }

  .product-info {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.1rem;
    margin-top: -0.46rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;

    .top {
      margin: 0.34rem 0 0 0.4rem;

      .top-image {
        width: 0.88rem;
        height: 0.88rem;
      }

      .top-right {
        margin-top: 0.07rem;
        margin-left: 0.31rem;

        .top-right-text {
          overflow-wrap: break-word;
          color: rgba(34, 34, 34, 1);
          font-size: 0.26rem;
          font-family: SourceHanSansCN-Normal;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
        }

        .top-right-bottom {
          overflow-wrap: break-word;
          font-size: 0;
          font-family: SourceHanSansCN-Bold;
          font-weight: 700;
          text-align: left;
          white-space: nowrap;
          margin: 0.15rem 0 0 0.03rem;

          .top-right-bottom-text {
            overflow-wrap: break-word;
            color: rgba(34, 34, 34, 1);
            font-size: 0.24rem;
            font-family: SourceHanSansCN-Bold;
            font-weight: 700;
            text-align: left;
            white-space: nowrap;
            line-height: 0.4rem;
          }

          .top-right-bottom-amount {
            overflow-wrap: break-word;
            color: rgba(34, 34, 34, 1);
            font-size: 0.36rem;
            font-family: DIN-Bold;
            font-weight: 700;
            text-align: left;
            white-space: nowrap;
            line-height: 0.4rem;
          }
        }
      }
    }

    .center {
      margin: 0.36rem 0.4rem 0 0.4rem;

      .center-top {
        background-color: rgba(255, 255, 255, 1);
        border-radius: 27px;
        height: 0.54rem;
        border: 1px solid rgba(252, 208, 206, 1);
        width: 1.8rem;

        .center-top-text {
          overflow-wrap: break-word;
          color: rgba(251, 107, 98, 1);
          font-size: 0.24rem;
          font-family: SourceHanSansCN-Normal;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
        }
      }
    }

    .bottom {
      background-color: rgba(251, 107, 98, 1);
      border-radius: 44px;
      height: 0.88rem;
      margin: 0.29rem 0.45rem 0.41rem 0.45rem;

      .bottom-text {
        height: 0.88rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 0.32rem;
        font-family: SourceHanSansCN-Medium;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }

  .group_2 {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    margin: 0.19rem 0.3rem 0 0.3rem;

    .text_1 {
      overflow-wrap: break-word;
      color: rgba(34, 34, 34, 1);
      font-size: 0.28rem;
      font-family: SourceHanSansCN-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 0.4rem;
      margin: 0.28rem 0 0 0.4rem;
    }

    .section_2 {
      margin-top: 0.34rem;
      margin-right: 0.4rem;
      margin-left: 0.4rem;

      .image_4 {
        width: 0.88rem;
        height: 0.88rem;
      }
    }

    .text-group_7 {
      margin-left: 0.28rem;

      .text_2 {
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 0.26rem;
        font-family: SourceHanSansCN-Regular;
        font-weight: normal;
        text-align: left;
        line-height: 0.5rem;
      }

      .phone {
        font-family: DIN;
        font-weight: bold;
        font-size: 0.3rem;
        color: #222222;
        line-height: 0.4rem;
      }
    }

    .text-wrapper_1 {
      background: url(https://lanhu-oss.lanhuapp.com/61b8f25a2ea556372479e986aada20da)
        100% no-repeat;
      background-size: 100% 100%;
      margin: 0.28rem 0.45rem 0.22rem 0.45rem;
      padding: 0.13rem 0;

      .text_4 {
        overflow-wrap: break-word;
        color: rgba(251, 107, 98, 1);
        font-size: 0.26rem;
        font-family: SourceHanSansCN-Regular;
        font-weight: normal;
        background: #feeeed;
        border-radius: 27px;
        height: 0.52rem;
      }
    }
  }

  .loan-process {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.1rem;
    justify-content: flex-center;
    margin: 0.2rem 0.3rem 0 0.3rem;

    .loan-process-title {
      width: 1.1rem;
      height: 0.27rem;
      overflow-wrap: break-word;
      color: rgba(32, 32, 32, 1);
      font-size: 0.28rem;
      font-family: SourceHanSansCN-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 0.4rem;
      margin: 0.33rem 0 0 0.4rem;
    }

    .loan-process-content {
      margin: 0.27rem 0.3rem 0.33rem 0.3rem;

      .center {
        margin-right: 0.15rem;
        margin-left: 0.15rem;
      }

      .item {
        background-color: rgba(244, 244, 244, 1);
        border-radius: 10px;
        padding: 0.2rem 0;

        .label {
          width: 0.33rem;
          height: 0.36rem;
        }

        .label_1 {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.09rem;
        }

        .text-title {
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.2rem;
          font-family: SourceHanSansCN-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.4rem;
          margin-top: 0.15rem;
        }

        .status {
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 0.2rem;
          color: #fb6b62;
          line-height: 0.4rem;
        }

        .div-bottom {
          background-color: rgba(251, 107, 98, 1);
          border-radius: 0.1rem;
          height: 0.4rem;
          width: 1rem;
          margin-top: 0.11rem;

          .text {
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 0.2rem;
            font-family: SourceHanSansCN-Medium;
            font-weight: 500;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .tel-con {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 0.24rem;
    color: #999999;
    margin-top: 0.38rem;
    margin-bottom: 0.18rem;

    a {
      color: #999999;
      margin-left: 0.09rem;
    }
  }

  .bottom {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 0.24rem;
    color: #999999;
    line-height: 0.4rem;
    margin-top: 0.2rem;
  }
}
</style>
